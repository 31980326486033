<template>
  <path id="Tracé_574" data-name="Tracé 574" d="M1,48a1,1,0,0,1-1-1V34a1,1,0,0,1,1-1H16V13A13.016,13.016,0,0,1,29,0H51A13.014,13.014,0,0,1,64,13V35A13.014,13.014,0,0,1,51,48ZM18,13V46H51A11.012,11.012,0,0,0,62,35V13A11.011,11.011,0,0,0,51,2H29A11.013,11.013,0,0,0,18,13ZM15,44v2h1V35H2V46H4V43a1,1,0,0,1,2,0v3H7V44a1,1,0,0,1,2,0v2h1V43a1,1,0,0,1,2,0v3h1V44a1,1,0,0,1,2,0Zm6,0a1,1,0,0,1-1-1V37a1,1,0,1,1,2,0v5h5a1,1,0,1,1,0,2Zm7-20A12,12,0,1,1,40,36,12.012,12.012,0,0,1,28,24Zm2,0A10,10,0,1,0,40,14,10.011,10.011,0,0,0,30,24Z" transform="translate(0.5 0.5)" stroke="rgba(0,0,0,0)" stroke-width="1"/>
</template>

<script>
export default {
name: "IconDimensionnement"
}
</script>

<style scoped>

</style>